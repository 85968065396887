<!-- https://vuetifyjs.com/en/api/v-snackbar/#component-pages -->
<template>

  <v-snackbar 
    :absolute="config.absolute"
    :bottom="config.position === 'bottom'"
    :color="config.color" 
    :centered="config.position === 'center'"
    :fixed="config.fixed"
    :left="config.position === 'left'"
    :multi-line="message && message.length > 40"
    :right="config.position === 'right'"
    :timeout="config.timeout" 
    :top="config.position === 'top'"
    :transition="config.transition"
    :vertical="config.vertical"
    v-model="enabled"
  >
    <div class="ctn d-flex align-center">
      <span class="message mx-3">{{ message }}</span>
      <v-icon  @click="enabled = false">mdi-close</v-icon>
    </div>
  </v-snackbar>
</template>
<script>
import { EventBus } from '@/services/event-bus'; 

export default {
  name: 'SnackBar',
  data: () => ({ 
    enabled:  false,
    message: '',
    dynamicMessage: '',
    test: '',
    config: {
      app: false,
      absolute: false,
      centered: false,
      color:    'primary',
      fixed:    false,
      position: 'bottom',
      timeout:  -1,
      rounded: false,
      transition: 'slide-x-transition',
      vertical: false,
    }
  }),
  created () {
    EventBus.$on('showSnackBar', snackBarData => {
      if (!snackBarData.message.length)
       return 

      this.config = { ...this.config, ...snackBarData.config }
      this.message = `${this.$t(snackBarData.message)}${snackBarData.dynamicMessage || ''}`
      this.enabled = true      
    });
  },
}
</script>
<style lang="scss" scoped>
  /*::v-deep {
    .v-snack__wrapper {
      position: fixed !important;
      bottom: 0 !important;
      left: 50%;
      transform: translateX(-50%);
    }
  }*/
  .v-snack__content { 
    padding: 14px 3px;

    .message { font-size: 15px; }
  }

  .v-icon { 
    margin-left: auto !important; 
    text-shadow: 1px 1px 4px black
  }

  span { max-width: 330px; }

</style>